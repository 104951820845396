figure {
    display: block; /* Блочный элемент */
    width: 200px; /* Ширина */
    height: 200px; /* Высота */
    float: left; /* Блоки выстраиваются по горизонтали */
    margin: 2rem; /* Отступы */
    text-align: center; /* Выравнивание по центру */
}

figure img {
    /*border: 2px solid #8b8e4b;*/ /* Параметры рамки */
    width: 200px; /* Ширина */
    height: 200px; /* Высота */
}